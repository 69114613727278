import CrudTable from '../../crud/CrudTable'
import { rentalLogService, userService, rentService } from '../../../services'

const initFilter = {
    title: null,
    description: null,
    rental: null,
    user: null,
    from: null,
    to: null,
}

export default function RentalLog() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'title',
            col: 4,
        },
        {
            type: 'input',
            name: 'description',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'rental',
            col: 4,
            service: rentService.combo,
        },
        {
            type: 'autocomplete',
            name: 'user',
            col: 4,
            service: userService.combo,
        },
        {
            type: 'input',
            name: 'from',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'input',
            name: 'to',
            inputtype: 'datetime-local',
            col: 4,
        },
    ]

    const paintCell = {
        description: (value, object) => {
            if (value.length > 100) {
                return <>{value.substring(0, 2)}...</>
            } else {
                return <>{value}</>
            }
        },
    }

    return (
        <CrudTable
            service={rentalLogService}
            urledit={'/rental-log/edit/'}
            urlnew={'/rental-log/new/'}
            filter={initFilter}
            editable={false}
            deleteaction={false}
            paintCell={paintCell}
            i18n={'rentalLog'}
            fieldstable={[
                'createDate',
                'title',
                'description',
                'rental.id',
                'rental.car.number',
                'rental.user.name|rental.user.lastname',
            ]}
            estructureForm={createEstructureFilter}
            labels={{ 'rental.user.name|rental.user.lastname': 'Usuario' }}
        />
    )
}
