import CrudTable from '../../crud/CrudTable'
import { shareLogService, userService, shareService } from '../../../services'

const initFilter = {
    title: null,
    description: null,
    share: null,
    user: null,
    from: null,
    to: null,
}

export default function ShareLog() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'title',
            col: 4,
        },
        {
            type: 'input',
            name: 'description',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'share',
            col: 4,
            service: shareService.combo,
        },
        {
            type: 'autocomplete',
            name: 'user',
            col: 4,
            service: userService.combo,
        },
        {
            type: 'input',
            name: 'from',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'input',
            name: 'to',
            inputtype: 'datetime-local',
            col: 4,
        },
    ]
    const paintCell = {
        description: (value, object) => {
            if (value.length > 100) {
                return <>{value.substring(0, 2)}...</>
            } else {
                return <>{value}</>
            }
        },
    }

    return (
        <CrudTable
            service={shareLogService}
            urledit={'/share-log/edit/'}
            urlnew={'/share-log/new/'}
            filter={initFilter}
            editable={false}
            deleteaction={false}
            paintCell={paintCell}
            i18n={'shareLog'}
            fieldstable={[
                'createDate',
                'title',
                'description',
                'share.id',
                'share.car.number',
                'share.user.name|share.user.lastname',
            ]}
            estructureForm={createEstructureFilter}
            labels={{ 'share.user.name|share.user.lastname': 'Usuario' }}
        />
    )
}
