import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { shareLogService as service, shareService } from '../../../services'
import { useTranslation } from 'react-i18next'

const moment = require('moment')

const initElement = {
    share: { id: null },
    title: '',
    description: '',
}

export default function FormShareLog() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
        share: string
    }
    let { id, share } = useParams<FormParms>()
    const [idShare, setIdShare] = useState(share ? Number(share) : 0)
    const { t, i18n } = useTranslation()

    console.log('Valor de idShare:', idShare)
    console.log("Parámetro 'share' de la URL:", share)

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'share.id',
            col: 4,
            service: shareService.combo,
        },
        {
            type: 'input',
            name: 'title',
            col: 4,
        },
        {
            type: 'textarea',
            name: 'description',
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['share'] = element.share ? element.share : { id: null }
        objectedit['title'] = element.title ? element.title : ''
        objectedit['description'] = element.description
            ? element.description
            : ''

        if (element['user'] == null || element['user']['id'] == null) {
            objectedit['user'] = {
                id: -1,
                name: 'Todos',
                email: 'todos@todos.com',
            }
        }

        if (objectedit['share']['id']) {
            objectedit['share']['name'] =
                objectedit['share']['id'] +
                ' -- ' +
                objectedit['share']['start']
                    ? moment(Date.parse(objectedit['share']['start'])).format(
                          'DD/MM/YYYY'
                      )
                    : 'Fecha no disponible' +
                      ' -- ' +
                      (objectedit['share']['user'] &&
                      objectedit['share']['user']['email']
                          ? objectedit['share']['user']['email']
                          : '')
        }
        return objectedit
    }

    const isValid = function (object) {
        if (!object['share']) {
            return t('shareLog.error.share')
        }
        if (!object['title']) {
            return t('shareLog.error.title')
        }
        if (!object['description']) {
            return t('shareLog.error.description')
        }
        return null
    }
    console.log('Valor de idShare:', idShare)
    return (
        <>
            <CrudForm
                objectform={{
                    ...initElement,
                    ...{ share: { id: idShare, name: '' } },
                }}
                estructureform={estructureform}
                i18n={'shareLog'}
                urledit={'/share-log/edit/'}
                urlCancel={
                    idShare > 0 ? '/share/edit/' + idShare : '/share-log'
                }
                returnToSave={idShare > 0}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
