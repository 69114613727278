import CrudTable from '../../../crud/CrudTable'
import { extrarentService as service } from '../../../../services'

const initFilter = {
    name: null,
}

export default function Extrarent() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/extrarent/edit/'}
            urlnew={'/extrarent/new/'}
            filter={initFilter}
            i18n={'extrarent'}
            fieldstable={['name', 'price']}
            estructureForm={createEstructureFilter}
        />
    )
}
