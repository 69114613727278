import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../../crud/CrudForm'
import {
    pricesService as service,
    cartypeService,
    companyService,
} from '../../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    name: '',
    carType: { name: null, id: null },
    company: { name: null, id: null },
    free_km: null,
    price_km: null,
    price_min: null,
    price_extra: null,
    price_standby: null,
    price_free_km: null,
    rent_1: null,
    rent_2: null,
    rent_3: null,
    rent_5: null,
    rent_7: null,
    rent_14: null,
    rent_30: null,
    notPayRent: null,
    notPayShare: null,
    multiDriveRent: null,
    typePrice: 'SHARE',
}

export default function FormPricesShare() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'name',
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'carType.id',
            col: 3,
            service: cartypeService.combo,
        },
        {
            type: 'autocomplete',
            name: 'company.id',
            col: 3,
            service: companyService.combo,
        },
        {
            type: 'checkbox',
            name: 'isShareRent',
            col: 3,
        },
        {
            type: 'title',
            name: 'titleshare',
            step: 0.01,
            col: 12,
        },
        {
            type: 'input',
            name: 'free_km',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'minuteInclude',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'price_free_km',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'price_km',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'price_standby',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'price_km_base',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'price_min_base',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'secureShare',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'minor25Share',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'minor3Share',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'fianceShare',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'notPayShare',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('cartype.error.name')
        }
        if (
            !object['carType'] ||
            !object['carType']['id'] ||
            object['carType']['id'] <= 0
        ) {
            return t('cartype.error.carType')
        }
        if (!object['free_km'] || object['free_km'] < 0) {
            return t('cartype.error.free_km')
        }
        if (!object['price_km'] || object['price_km'] < 0) {
            return t('cartype.error.price_km')
        }
        if (!object['price_standby'] || object['price_standby'] < 0) {
            return t('cartype.error.price_standby')
        }
        if (!object['price_free_km'] || object['price_free_km'] < 0) {
            return t('cartype.error.price_free_km')
        }
        if (!object['notPayShare'] || object['notPayShare'] < 0) {
            return t('cartype.error.notPayShare')
        }
        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'pricesshare'}
                urledit={'/pricesshare/edit/'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
