import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { userlogService } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import Grid from '@mui/material/Grid'
import Moment from 'react-moment'

export default function TabUserLog(props) {
    const [filter, setFilter] = useState({
        user: props.idclient,
        actionType: null,
        searchText: null
    })

    const { t } = useTranslation()

    const createEstructureFilter = [
        {
            type: 'input',
            name: 'actionType',
            col: 4,
            label: t('userLog.filter.field.actionType')
        },
        {
            type: 'input',
            name: 'searchText',
            col: 8,
            label: t('userLog.filter.field.searchText')
        }
    ]

    const paintCell = {
        createDate: (value) => new Date(value).toLocaleString(),
        actionDescription: (value) => (
            <div style={{
                maxWidth: '200px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>
                {value}
            </div>
        ),
        'user.name|user.lastname': (_, record) => (
            `${record.user?.name || ''} ${record.user?.lastname || ''}`
        )
    }

    useEffect(() => { }, [])

    useEffect(() => {
        setFilter(prev => ({ ...prev, user: props.idclient }))
    }, [props.idclient])

    return (
        <>
            <CrudTab
                service={userlogService}
                filter={filter}
                i18n={'userLog'}
                newbtn={true}
                urlnew={`/user-log/new/${props.idclient}`}
                fieldstable={[
                    'createDate',
                    'actionType',
                    'actionDescription',
                    'user.name|user.lastname'
                ]}
                paintCell={paintCell}
                estructureForm={createEstructureFilter}
                haveother={false}
                selectable={false}
                editable={false}
                deleteaction={false}
                onFilterUpdate={(newFilter) => setFilter(newFilter)}
            />
            <ToastContainer />
        </>
    )
}
