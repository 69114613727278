import CrudTable from '../../../crud/CrudTable'
import {
    pricesService as service,
    cartypeService,
    companyService,
} from '../../../../services'

const initFilter = {
    name: null,
    typePrice: 'SHARE',
}

export default function PricesShare() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'cartype',
            col: 4,
            service: cartypeService.combo,
        },
        {
            type: 'autocomplete',
            name: 'company',
            col: 4,
            service: companyService.combo,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/pricesshare/edit/'}
            urlnew={'/pricesshare/new/'}
            i18n={'pricesshare'}
            filter={initFilter}
            fieldstable={[
                'name',
                'carType.name',
                'company.name',
                'price_km',
                'price_standby',
                'price_km_base',
                'price_min_base',
                'minuteInclude',
                'free_km',
                'fianceShare',
                'isShareRent',
            ]}
            estructureForm={createEstructureFilter}
        />
    )
}
