import CrudTable from '../../crud/CrudTable'
import {
    rateUserService as service,
    carService,
    userService,
} from '../../../services'
import EyeIcon from '@mui/icons-material/RemoveRedEye'
import PersonIcon from '@mui/icons-material/Person'

const moment = require('moment')

const initFilter = {
    car: null,
    user: null,
    rate: null,
    from: null,
    to: null,
}

export default function Rate() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'from',
            inputtype: 'date',
            col: 4,
        },
        {
            type: 'input',
            name: 'to',
            inputtype: 'date',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'car',
            service: carService.combo,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'user',
            service: userService.combo,
            col: 4,
        },
        {
            type: 'input',
            name: 'rate',
            inputtype: 'number',
            col: 4,
        },
    ]

    const getUrlImage = function (namefile) {
        if (namefile) {
            if (namefile.startsWith('http')) {
                return namefile
            } else {
                var nameImage = namefile
                nameImage = nameImage.startsWith('/')
                    ? nameImage
                    : '/' + nameImage
                return process.env.REACT_APP_HOST + '/image' + nameImage
            }
        } else {
            return ''
        }
    }
    const goTo = function (row) {
        if (row['share'] != null && row['share']['id'] != null) {
            window.location.href = '/share/edit/' + row['share']['id']
        } else if (row['rental'] != null && row['rental']['id'] != null) {
            window.location.href = '/rent/edit/' + row['rental']['id']
        }
    }

    const goToUser = function (row) {
        if (row['user'] != null && row['user']['id'] != null) {
            window.location.href = '/users/edit/' + row['user']['id']
        }
    }

    const paintCell = {
        rate: (value, object) => {
            return (
                <>
                    {value != null && value >= 1 ? (
                        <img src={'/stargreen.png'} alt="" width={'30px'} />
                    ) : (
                        <img src={'/starwhite.png'} alt="" width={'30px'} />
                    )}
                    {value != null && value >= 2 ? (
                        <img src={'/stargreen.png'} alt="" width={'30px'} />
                    ) : (
                        <img src={'/starwhite.png'} alt="" width={'30px'} />
                    )}{' '}
                    {value != null && value >= 3 ? (
                        <img src={'/stargreen.png'} alt="" width={'30px'} />
                    ) : (
                        <img src={'/starwhite.png'} alt="" width={'30px'} />
                    )}{' '}
                    {value != null && value >= 4 ? (
                        <img src={'/stargreen.png'} alt="" width={'30px'} />
                    ) : (
                        <img src={'/starwhite.png'} alt="" width={'30px'} />
                    )}{' '}
                    {value != null && value >= 5 ? (
                        <img src={'/stargreen.png'} alt="" width={'30px'} />
                    ) : (
                        <img src={'/starwhite.png'} alt="" width={'30px'} />
                    )}
                </>
            )
        },
        price: (value, object) => {
            if (object['share'] && object['share']['total']) {
                return <>{object['share']['total'].toFixed(2)}€</>
            } else if (object['rental'] && object['rental']['total']) {
                return <>{object['rental']['total'].toFixed(2)}€</>
            } else {
                return <></>
            }
        },
        km: (value, object) => {
            if (object['share'] && object['share']['km_real']) {
                return <>{object['share']['km_real'].toFixed(2)}km</>
            } else if (object['rental'] && object['rental']['total']) {
                return <>{object['rental']['km_real'].toFixed(2)}km</>
            } else {
                return <>0.00km</>
            }
        },
        createDate: (value, object) => {
            if (value) {
                return (
                    <>{moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')}</>
                )
            }
        },
    }

    return (
        <CrudTable
            service={service}
            filter={initFilter}
            i18n={'rateuser'}
            paintCell={paintCell}
            hiddenButton={true}
            hiddencol={true}
            keytable={'rateuser'}
            fieldstable={[
                'createDate',
                'car.number',
                'user.email',
                'rate',
                'comment',
                'share.id',
                'rental.id',
                'km',
                'price',
            ]}
            editable={false}
            deleteaction={false}
            moreActions={[
                {
                    handle: goTo,
                    icon: <EyeIcon />,
                },
                {
                    handle: goToUser,
                    icon: <PersonIcon />,
                },
            ]}
            estructureForm={createEstructureFilter}
        />
    )
}
