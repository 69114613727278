import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { userlogService as service, userService } from '../../../services'
import { useTranslation } from 'react-i18next'

const moment = require('moment')

const initElement = {
    user: { id: null },
    actionType: '',
    actionDescription: '',
    occurrenceDate: moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss") + 'Z'
}

export default function FormUserLog() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParams = {
        id?: string;
        userId: string;
    }
    let { userId } = useParams<FormParams>()
    const [idclient, setIdClient] = useState(userId ? Number(userId) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'user.id',
            col: 4,
            service: userService.combo,
            readonly: idclient > 0,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => `${option.name} ${option.lastname}`
        },
        {
            type: 'input',
            name: 'actionType',
            col: 4,
            required: true
        },
        {
            type: 'textarea',
            name: 'actionDescription',
            col: 8,
            rows: 3,
            required: true
        },
        {
            type: 'input',
            name: 'occurrenceDate',
            inputtype: 'datetime-local',
            col: 4,
            required: true,
            valueFormatter: (value) => {
                return moment.utc(value).local().format("YYYY-MM-DDTHH:mm")
            },
            parseValue: (value) => {
                return moment(value).utc().format("YYYY-MM-DDTHH:mm:ss") + 'Z'
            }
        }
    ]

    const recoveryElement = (objectedit, element) => {
        return {
            ...objectedit,
            user: element?.user?.id
                ? { id: element.user.id }
                : { id: -1, name: 'Todos', email: 'todos@todos.com' },
            actionType: element?.actionType || '',
            actionDescription: element?.actionDescription || '',
            occurrenceDate: element?.occurrenceDate
                ? moment.utc(element.occurrenceDate).local().format("YYYY-MM-DDTHH:mm")
                : moment().format("YYYY-MM-DDTHH:mm")
        }
    }

    const isValid = (object) => {
        if (!object.user?.id)
            return t('userLog.error.user')
        if (!object.actionType?.trim())
            return t('userLog.error.actionType')
        if (!object.actionDescription?.trim())
            return t('userLog.error.actionDescription')
        if (!object.occurrenceDate)
            return t('userLog.error.occurrenceDate')
        return null
    }

    return (
        <>
            <CrudForm
                objectform={{
                    ...initElement,
                    ...{ user: { id: idclient, name: '' } },
                }}
                estructureform={estructureform}
                i18n={'userLog'}
                urledit={'/user-log/edit/'}
                urlCancel={idclient > 0 ? '/users/edit/' + idclient : '/user-log'}
                returnToSave={idclient > 0}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
