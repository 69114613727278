import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { paymentdataService as service } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import DoneIcon from '@mui/icons-material/Done'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import { toast, ToastContainer } from 'react-toastify'
import { Modal, Button, Grid, TextField } from '@mui/material'
import { Box } from '@mui/system'

export default function TabPaymentData(props) {
    const [filter, setFilter] = useState({
        user: props.idclient,
        from: null,
        to: null,
    })

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxWidth: '80%',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    const [card, setCard] = useState<any>(null)
    const [openModal, setOpenModal] = useState(false)
    const [data, setData] = useState<{ id: any; ammount: any; concept: any }>({
        id: null,
        ammount: null,
        concept: null,
    })

    const { t } = useTranslation()

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            user: props.idclient,
            from: null,
            to: null,
        })
    }, [props.idclient])

    const paintvalue = {
        credit_month: (value, object) => {
            return (
                (object['credit_month'] != null
                    ? object['credit_month']
                    : '--') +
                '/' +
                (object['credit_year'] != null ? object['credit_year'] : '--')
            )
        },
        stripe: (value, object) => {
            if (
                object['stripe_token'] != null &&
                object['stripe_token'] != ''
            ) {
                return <DoneIcon style={{ color: 'green' }} />
            } else {
                return <></>
            }
        },
        redsys: (value, object) => {
            if (
                object['redsys_token'] != null &&
                object['redsys_token'] != ''
            ) {
                return <DoneIcon style={{ color: 'green' }} />
            } else {
                return <></>
            }
        },
    }

    const goToPay = function (row) {
        setCard(row)
    }

    useEffect(() => {
        if (card != null) {
            setOpenModal(true)
        }
    }, [card])

    const handleCloseModal = () => {
        setOpenModal(false)
        setCard(null)
    }

    const sendPay = () => {
        setOpenModal(false)

        var toSend = { ...data }
        toSend.id = card.id

        service.pay(toSend, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha cobrado', {
                    position: toast.POSITION.TOP_CENTER,
                })
            } else {
                toast.error('No se ha podido realizar el cobro', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }

            setCard(null)
        })
    }

    return (
        <>
            <CrudTab
                service={service}
                editable={false}
                filter={filter}
                i18n={'paymentdata'}
                fieldstable={[
                    'credit_number',
                    'credit_month',
                    'stripe',
                    'redsys',
                ]}
                haveother={false}
                selectable={false}
                hiddenFilter={true}
                paintCell={paintvalue}
                moreActions={[
                    {
                        handle: goToPay,
                        icon: <CurrencyExchangeIcon />,
                    },
                ]}
            />
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={style}>
                    <Grid container>
                        <Grid xs={12} sm={12}>
                            <TextField
                                variant="standard"
                                sx={{ width: '100%' }}
                                id={'ammount'}
                                label={'Cantidad'}
                                value={data.ammount}
                                onChange={(event) => {
                                    var value = event.target.value
                                    var aux = { ammount: value }
                                    setData((currentState) => ({
                                        ...currentState,
                                        ...aux,
                                    }))
                                }}
                                type={'number'}
                                inputProps={{
                                    step: 0.01,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid xs={12} sm={12} style={{ paddingTop: '20px' }}>
                            <TextField
                                variant="standard"
                                sx={{ width: '100%' }}
                                id={'concept'}
                                label={'Concepto'}
                                value={data.concept}
                                onChange={(event) => {
                                    var value = event.target.value
                                    var aux = { concept: value }
                                    setData((currentState) => ({
                                        ...currentState,
                                        ...aux,
                                    }))
                                }}
                                type={'text'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            sm={12}
                            style={{ paddingTop: '20px', textAlign: 'right' }}
                        >
                            <Button
                                variant="contained"
                                color="inherit"
                                onClick={() => handleCloseModal()}
                            >
                                {' '}
                                Cerrar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => sendPay()}
                                style={{ marginLeft: '20px' }}
                            >
                                {' '}
                                Cobrar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <ToastContainer />
        </>
    )
}
