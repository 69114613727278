import BaseService from './_base.service'

const URL = '/admin/rental-log'

export const rentalLogService = {
    search,
    combo,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    login,
    recovery,
    logout,
    allFilterExcel,
    allFilterPdf,
    allFilterWord,
}

function search(offset, callback) {
    return BaseService.get(URL + '/all/', callback, false, false)
}

function combo(callback) {
    return BaseService.get(URL + '/combo/', callback, false, false)
}

function page(page, size, callback) {
    return BaseService.get(
        URL + '/page/' + page + '/' + size,
        callback,
        false,
        false
    )
}

function pageFilter(page, size, filter, callback) {
    return BaseService.post(
        URL + '/page/' + page + '/' + size,
        filter,
        callback,
        false,
        false
    )
}

function one(id, callback) {
    return BaseService.get(URL + '/one/' + id, callback)
}

function create(bodyelement, callback) {
    //Me falta este endpoint
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL + '/' + id, body, callback, true)
}

function remove(id, callback) {
    return BaseService.delete(URL + '/' + id, callback)
}

function removeLogic(id, callback) {
    return BaseService.delete(URL + '/' + id + '/logic', callback)
}

function login(user, callback) {
    let body = JSON.stringify(user)
    return BaseService.post('/login', body, callback, true)
}

function recovery(email, callback) {
    //Me falta este endpoint
    // localStorage.setItem('user', JSON.stringify(user));
    let body = JSON.stringify({ email })
    return BaseService.post('/recoverypass', body, callback, true)
}

function logout() {
    //Me falta este endpoint
    localStorage.removeItem('user')
}

function allFilterPdf(filter, callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(
        URL + '/list/pdf',
        filter,
        'rental_log.pdf',
        callback
    )
}

function allFilterExcel(filter, callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(
        URL + '/list/excel',
        filter,
        'rental_log.xlsx',
        callback
    )
}

function allFilterWord(filter, callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(
        URL + '/list/word',
        filter,
        'rental_log.doc',
        callback
    )
}
