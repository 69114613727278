import CrudTable from '../../crud/CrudTable'
import { zoneService as service } from '../../../services'

const initFilter = {
    name: null,
}

export default function Zone() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
    ]

    const paintCell = {
        typeZone: (value, object) => {
            if (value) {
                if (value == 'SHARE') {
                    return <>Solo Share</>
                } else if (value == 'RENT') {
                    return <>Solo RENT, tanto finalizar como iniciar</>
                } else if (value == 'RENT_IN') {
                    return <>Solo iniciar RENT</>
                } else if (value == 'RENT_OUT') {
                    return <>Solo finalizar RENT</>
                } else if (value == 'ALL') {
                    return <>Todos los servicios</>
                } else {
                    return <></>
                }
            } else {
                return <></>
            }
        },
    }

    return (
        <CrudTable
            service={service}
            urledit={'/zone/edit/'}
            urlnew={'/zone/new/'}
            filter={initFilter}
            i18n={'zone'}
            paintCell={paintCell}
            fieldstable={[
                'name',
                'city',
                'state',
                'typeZone',
                'price',
                'pricesDistance',
                'maxKm',
                'enable',
            ]}
            estructureForm={createEstructureFilter}
        />
    )
}
