import * as React from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import {
    Assessment as AssessmentIcon,
    Build as BuildIcon,
} from '@mui/icons-material'
import PaymentIcon from '@mui/icons-material/Payment'
import ConstructionIcon from '@mui/icons-material/Construction'
import CarCrashIcon from '@mui/icons-material/CarCrash'
import FmdBadIcon from '@mui/icons-material/FmdBad'
import EuroIcon from '@mui/icons-material/Euro'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import BusinessIcon from '@mui/icons-material/Business'
import DiscountIcon from '@mui/icons-material/Discount'
import RouterIcon from '@mui/icons-material/Router'
import MenuElement from './MenuItem'
import { rolUser } from '../../utils/session'
import MenuParent from './MenuParent'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import RouteIcon from '@mui/icons-material/Route'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import PlaceIcon from '@mui/icons-material/Place'
import InventoryIcon from '@mui/icons-material/Inventory'
import CarRentalIcon from '@mui/icons-material/CarRental'
import MinorCrashIcon from '@mui/icons-material/MinorCrash'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import AlertIcon from '@mui/icons-material/CrisisAlert'
import ImageIcon from '@mui/icons-material/Image'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import CarRepairIcon from '@mui/icons-material/CarRepair'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import MapIcon from '@mui/icons-material/Map'
import LoopIcon from '@mui/icons-material/Loop'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import StarIcon from '@mui/icons-material/Star'
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import DatasetIcon from '@mui/icons-material/Dataset'
import HistoryIcon from '@mui/icons-material/History'
import ElectricCarIcon from '@mui/icons-material/ElectricCar'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const closedMixinXs = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `0px`,
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
        '& .MuiListItemText-root': {
            display: 'none',
        },
        '& .MuiListItem-root': {
            paddingLeft: '8px !important',
        },
        '& .MuiListItemIcon-root': {
            minWidth: '26px',
        },
    }),
    [theme.breakpoints.down('sm')]: {
        ...(!open && {
            ...closedMixinXs(theme),
            '& .MuiDrawer-paper': closedMixinXs(theme),
            '& .MuiListItemText-root': {
                display: 'none',
            },
        }),
    },
}))

export default function Menu(props) {
    const theme = useTheme()
    const [open, setOpen] = React.useState(props.open)
    const [openTrip, setOpenTrip] = React.useState(false)
    const [openSales, setOpenSales] = React.useState(false)
    const [openBuys, setOpenBuys] = React.useState(false)
    const [openConfig, setOpenConfig] = React.useState(false)
    const [openData, setOpenData] = React.useState(false)
    const [openCash, setOpenCash] = React.useState(false)
    const [openPay, setOpenPay] = React.useState(false)
    const [openReport, setOpenReport] = React.useState(false)
    const [openContable, setOpenContable] = React.useState(false)
    const [openEmpl, setOpenEmpl] = React.useState(false)
    const [openBill, setOpenBill] = React.useState(false)
    const [openSus, setOpenSus] = React.useState(false)
    const [openFaqs, setOpenFaqs] = React.useState(false)
    const isAdmin = rolUser() === 'ADMIN'

    const t = props.t

    const closeAll = (id) => {
        if (id != 1) {
            setOpenTrip(false)
        } else {
            setOpenTrip(!openTrip)
        }
        if (id != 2) {
            setOpenConfig(false)
        } else {
            setOpenConfig(!openConfig)
        }
        if (id != 3) {
            setOpenData(false)
        } else {
            setOpenData(!openData)
        }
        if (id != 4) {
            setOpenSales(false)
        } else {
            setOpenSales(!openSales)
        }
        if (id != 5) {
            setOpenBuys(false)
        } else {
            setOpenBuys(!openBuys)
        }
        if (id != 6) {
            setOpenCash(false)
        } else {
            setOpenCash(!openCash)
        }
        if (id != 7) {
            setOpenPay(false)
        } else {
            setOpenPay(!openPay)
        }

        if (id != 8) {
            setOpenReport(false)
        } else {
            setOpenReport(!openReport)
        }

        if (id != 9) {
            setOpenContable(false)
        } else {
            setOpenContable(!openContable)
        }

        if (id != 10) {
            setOpenEmpl(false)
        } else {
            setOpenEmpl(!openEmpl)
        }
        if (id != 11) {
            setOpenBill(false)
        } else {
            setOpenBill(!openEmpl)
        }
        if (id != 12) {
            setOpenSus(false)
        } else {
            setOpenSus(!openSus)
        }
        if (id != 13) {
            setOpenFaqs(false)
        } else {
            setOpenFaqs(!openFaqs)
        }
    }

    React.useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={props.handleDrawerClose}>
                    {theme.direction === 'rtl' ? (
                        <ChevronRightIcon />
                    ) : (
                        <ChevronLeftIcon />
                    )}
                </IconButton>
            </DrawerHeader>
            <Divider />
            {isAdmin && (
                <>
                    <MenuElement
                        url={'/dashboard'}
                        icon={<DashboardIcon />}
                        text={t('menu.dashboard')}
                    />
                    <MenuElement
                        url={'/users'}
                        icon={<PeopleAltIcon />}
                        text={t('menu.users')}
                    />
                    <MenuElement
                        url={'/userbusiness'}
                        icon={<EmojiTransportationIcon />}
                        text={t('menu.userbusiness')}
                    />
                    <MenuElement
                        url={'/usercompany'}
                        icon={<ManageAccountsIcon />}
                        text={t('menu.usercompany')}
                    />
                    <MenuElement
                        url={'/alerts'}
                        icon={<AlertIcon />}
                        text={t('menu.alerts')}
                    />
                    <MenuElement
                        url={'/company'}
                        icon={<BusinessIcon />}
                        text={t('menu.company')}
                    />
                    <MenuElement
                        url={'/device'}
                        icon={<RouterIcon />}
                        text={t('menu.device')}
                    />
                    <MenuElement
                        url={'/car'}
                        icon={<DirectionsCarIcon />}
                        text={t('menu.car')}
                    />
                    <MenuElement
                        url={'/share'}
                        icon={<MinorCrashIcon />}
                        text={t('menu.share')}
                    />
                    <MenuElement
                        url={'/rent'}
                        icon={<CarRentalIcon />}
                        text={t('menu.rent')}
                    />
                    <MenuParent
                        icon={<ReceiptLongIcon />}
                        text={t('menu.bill')}
                        open={openBill}
                        num={11}
                        close={closeAll}
                    >
                        <MenuElement
                            url={'/bill'}
                            icon={<ReceiptLongIcon />}
                            text={t('menu.bill')}
                        />
                        <MenuElement
                            url={'/bill/company'}
                            icon={<ReceiptLongIcon />}
                            text={t('menu.billcompany')}
                            hiddenDivider={true}
                        />
                    </MenuParent>

                    <MenuElement
                        url={'/coupon'}
                        icon={<DiscountIcon />}
                        text={t('menu.coupon')}
                    />
                    <MenuParent
                        icon={<TravelExploreIcon />}
                        text={t('menu.tripandmarks')}
                        open={openTrip}
                        num={1}
                        close={closeAll}
                    >
                        <MenuElement
                            url={'/trip'}
                            icon={<RouteIcon />}
                            text={t('menu.trip')}
                        />
                        <MenuElement
                            url={'/mark'}
                            icon={<PlaceIcon />}
                            text={t('menu.mark')}
                        />
                        <MenuElement
                            url={'/pack'}
                            icon={<InventoryIcon />}
                            text={t('menu.pack')}
                            hiddenDivider={true}
                        />
                    </MenuParent>
                    <MenuElement
                        url={'/administrator'}
                        icon={<SupervisedUserCircleIcon />}
                        text={t('menu.administrador')}
                    />
                    <MenuElement
                        url={'/ads'}
                        icon={<ImageIcon />}
                        text={t('menu.ads')}
                    />
                    <MenuElement
                        url={'/bono'}
                        icon={<ConfirmationNumberIcon />}
                        text={t('menu.bono')}
                    />
                    <MenuElement
                        url={'/rates'}
                        icon={<StarIcon />}
                        text={t('menu.rate')}
                    />
                    <MenuParent
                        icon={<QuestionMarkIcon />}
                        text={t('menu.faqs')}
                        open={openFaqs}
                        num={13}
                        close={closeAll}
                    >
                        <MenuElement
                            url={'/faqs'}
                            icon={<QuestionMarkIcon />}
                            text={t('menu.faqs')}
                            inside={true}
                        />
                        <MenuElement
                            url={'/faqs/category'}
                            icon={<QuestionAnswerIcon />}
                            text={t('menu.faqsCategory')}
                            inside={true}
                        />
                    </MenuParent>
                    <MenuParent
                        icon={<ConstructionIcon />}
                        text={t('menu.tools')}
                        open={openConfig}
                        num={2}
                        close={closeAll}
                    >
                        <MenuElement
                            url={'/configuration'}
                            icon={<BuildIcon />}
                            text={t('menu.config')}
                            inside={true}
                        />
                        <MenuElement
                            url={'/cartype'}
                            icon={<CarCrashIcon />}
                            text={t('menu.cartype')}
                            inside={true}
                        />
                        <MenuElement
                            url={'/modelcar'}
                            icon={<CarRepairIcon />}
                            text={t('menu.modelcar')}
                            inside={true}
                        />
                        <MenuElement
                            url={'/marktype'}
                            icon={<FmdBadIcon />}
                            text={t('menu.marktype')}
                            inside={true}
                        />
                        <MenuElement
                            url={'/statebill'}
                            icon={<PaymentIcon />}
                            text={t('menu.statebill')}
                            inside={true}
                        />
                        <MenuElement
                            url={'/pricesrent'}
                            icon={<EuroIcon />}
                            text={t('menu.pricesrent')}
                            inside={true}
                        />
                        <MenuElement
                            url={'/pricesshare'}
                            icon={<EuroIcon />}
                            text={t('menu.pricesshare')}
                            inside={true}
                        />
                        <MenuElement
                            url={'/zone'}
                            icon={<MapIcon />}
                            text={t('menu.zone')}
                            inside={true}
                        />
                        <MenuElement
                            url={'/locationrent'}
                            icon={<LocationCityIcon />}
                            text={t('menu.locationrent')}
                            inside={true}
                        />
                        <MenuElement
                            url={'/cardamagetype'}
                            icon={<TaxiAlertIcon />}
                            text={t('menu.cardamagetype')}
                            inside={true}
                            hiddenDivider={true}
                        />
                    </MenuParent>
                </>
            )}
        </Drawer>
    )
}
