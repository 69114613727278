import CrudTable from '../../crud/CrudTable'
import { alertService as service, userService } from '../../../services'

const initFilter = {
    name: null,
}

export default function Alerts() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'title',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'user',
            service: userService.combo,
            col: 4,
        },
    ]

    const paintCell = {
        email: (value, object) => {
            if (
                object['user'] &&
                object['user']['email'] &&
                object['user']['email'] != ''
            ) {
                return <>{object['user']['email']}</>
            } else {
                return <>Todos</>
            }
        },
    }

    return (
        <CrudTable
            service={service}
            urledit={'/alerts/edit/'}
            urlnew={'/alerts/new/'}
            filter={initFilter}
            editable={false}
            deleteaction={false}
            paintCell={paintCell}
            i18n={'alerts'}
            fieldstable={['createDate', 'title', 'desc', 'email']}
            estructureForm={createEstructureFilter}
        />
    )
}
