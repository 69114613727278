import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { faqsService as service, faqsCategoryService } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    title: null,
    description: null,
    category: {
        id: -1,
        name: null,
    },
}

export default function FormFaqs() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'title',
            col: 6,
        },
        {
            type: 'autocomplete',
            name: 'category.id',
            service: faqsCategoryService.combo,
            col: 6,
        },
        {
            type: 'textarea',
            name: 'description',
            col: 12,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit = { ...objectedit, ...element }
        if (objectedit['category'] && objectedit['category']['title']) {
            objectedit['category']['name'] = objectedit['category']['title']
        }

        if (!objectedit['category']) {
            objectedit['category'] = { id: null, title: null, name: null }
        }
        return objectedit
    }

    const isValid = function (object) {
        if (!object['title']) {
            return t('faqs.error.title')
        }
        if (!object['description']) {
            return t('faqs.error.description')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'faqs'}
                urledit={'/faqs/edit/'}
                urlCancel={'/faqs'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
