import CrudTable from '../../crud/CrudTable'
import { userlogService, userService } from '../../../services'

const initFilter = {
    user: null,
    actionType: null,
    searchText: null
}

export default function UserLog() {
    const createEstructureFilter = [
        {
            type: 'autocomplete',
            name: 'user',
            col: 4,
            service: userService.combo,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => `${option.name} ${option.lastname}`
        },
        {
            type: 'input',
            name: 'actionType',
            col: 4
        },
        {
            type: 'input',
            name: 'searchText',
            col: 4
        }
    ]

    const paintCell = {
        actionDescription: (value) => (
            <div className="truncate-text" style={{ maxWidth: '300px' }}>
                {value?.length > 100 ? `${value.substring(0, 97)}...` : value}
            </div>
        ),
        createDate: (value) => new Date(value).toLocaleString(),
        'user.name|user.lastname': (_, record) => (
            `${record.user?.name || ''} ${record.user?.lastname || ''}`
        )
    }

    return (
        <CrudTable
            service={userlogService}
            urledit={'/user-log/edit/'}
            urlnew={'/user-log/new/'}
            filter={initFilter}
            editable={false}
            deleteaction={false}
            paintCell={paintCell}
            i18n={'userLog'}
            estructureForm={createEstructureFilter}
            fieldstable={[
                'createDate',
                'actionType',
                'actionDescription',
                'user.name|user.lastname'
            ]}
        />
    )
}
