import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../../crud/CrudForm'
import {
    pricesService as service,
    cartypeService,
    companyService,
} from '../../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    name: '',
    carType: { name: null, id: null },
    company: { name: null, id: null },
    free_km: null,
    price_km: null,
    price_min: null,
    price_extra: null,
    price_standby: null,
    price_free_km: null,
    rent_1: null,
    rent_2: null,
    rent_3: null,
    rent_5: null,
    rent_7: null,
    rent_14: null,
    rent_30: null,
    notPayRent: null,
    notPayShare: null,
    multiDriveRent: null,
    typePrice: 'RENT',
}

export default function FormPricesRent() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'carType.id',
            col: 4,
            service: cartypeService.combo,
        },
        {
            type: 'autocomplete',
            name: 'company.id',
            col: 4,
            service: companyService.combo,
        },
        {
            type: 'title',
            name: 'titlerent',
            step: 0.01,
            col: 12,
        },
        {
            type: 'input',
            name: 'rent_1',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'rent_2',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'rent_3',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'rent_5',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'rent_7',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'rent_14',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'rent_30',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'title',
            name: 'titlerentsecure',
            step: 0.01,
            col: 12,
        },
        {
            type: 'input',
            name: 'secure_rent_1',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'secure_rent_2',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'secure_rent_3',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'secure_rent_5',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'secure_rent_7',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'secure_rent_14',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'secure_rent_30',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'maxSecure',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'title',
            name: 'titleextra',
            step: 0.01,
            col: 12,
        },
        {
            type: 'input',
            name: 'minor25Rent',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'minor3Rent',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'fianceRent',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'rentExtraRent1',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'rentExtraRentMore',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'notPayRent',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'multiDriveRent',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('cartype.error.name')
        }
        if (
            !object['carType'] ||
            !object['carType']['id'] ||
            object['carType']['id'] <= 0
        ) {
            return t('cartype.error.carType')
        }
        if (!object['rent_1'] || object['rent_1'] < 0) {
            return t('cartype.error.rent_1')
        }
        if (!object['rent_2'] || object['rent_2'] < 0) {
            return t('cartype.error.rent_2')
        }
        if (!object['rent_3'] || object['rent_3'] < 0) {
            return t('cartype.error.rent_3')
        }
        if (!object['rent_5'] || object['rent_5'] < 0) {
            return t('cartype.error.rent_5')
        }
        if (!object['rent_7'] || object['rent_7'] < 0) {
            return t('cartype.error.rent_7')
        }
        if (!object['rent_14'] || object['rent_14'] < 0) {
            return t('cartype.error.rent_14')
        }
        if (!object['rent_30'] || object['rent_30'] < 0) {
            return t('cartype.error.rent_30')
        }
        if (!object['notPayRent'] || object['notPayRent'] < 0) {
            return t('cartype.error.notPayRent')
        }
        if (!object['multiDriveRent'] || object['multiDriveRent'] < 0) {
            return t('cartype.error.multiDriveRent')
        }
        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'pricesrent'}
                urledit={'/pricesrent/edit/'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
