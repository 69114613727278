export const selectIconCar = (car) => {
    var result = '/markerFree.png'

    if (car['statusCar'] && car['statusCar'] == 'INSHARE') {
        if (car['speed'] && car['speed'] > 0) {
            result = '/markerShareActivo.png'
        } else {
            result = '/markerShareInactivo.png'
        }
    } else if (car['statusCar'] && car['statusCar'] == 'INRENT') {
        if (car['speed'] && car['speed'] > 0) {
            result = '/markerRentActivo.png'
        } else {
            result = '/markerRentInactivo.png'
        }
    } else if (car['statusCar'] && car['statusCar'] == 'BLOCK') {
        result = '/markerBlock.png'
    } else if (car['statusCar'] && car['statusCar'] == 'RESERVED') {
        result = '/markerRentInactivo.png'
    }

    return result
}

export const urlImage = (image) => {
    if (image.startsWith('http')) {
        return image
    } else {
        return (
            process.env.REACT_APP_HOST +
            '/image' +
            (image.startsWith('/') ? image : '/' + image)
        )
    }
}

export const nameCarDamageZone = (value) => {
    if (value == 'FRONT') {
        return <>Frente</>
    } else if (value == 'BACK') {
        return <>Culo</>
    } else if (value == 'LEFT') {
        return <>Lateral izquierdo</>
    } else if (value == 'RIGHT') {
        return <>Lateral derecho</>
    } else if (value == 'TOP') {
        return <>Techo</>
    } else {
        return <>{value}</>
    }
}
