import CrudTable from '../../crud/CrudTable'
import { faqsCategoryService as service } from '../../../services'

const initFilter = {
    title: null,
}

export default function FaqsCategory() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'title',
            col: 6,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/faqs/category/edit/'}
            urlnew={'/faqs/category/new/'}
            filter={initFilter}
            i18n={'faqsCategory'}
            fieldstable={['id', 'title']}
            estructureForm={createEstructureFilter}
        />
    )
}
