import axios from "axios";
import print from 'print-js'


class BaseService {
    HOST = '';

    constructor() {
        this.HOST = process.env.REACT_APP_HOST;
    }

    createHeader(){

      this.service = axios.create({
          headers: {
              'Authorization': this.authHeader(),
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Expose-Headers': 'X-refreshtoken, X-Authorization, X-Access-Token, X-Uid, X-Requested-With',
              'Access-Control-Allow-Headers': 'X-Requested-With, Origin, X-Requested-With, Content-Type, Accept, Authorization, refreshtoken, X-refreshtoken, X-Authorization, X-Access-Token, X-Uid'
          }
      });
      this.service.interceptors.response.use(this.handleSuccess, this.handleError);
    }

    authHeader() {
        let token = localStorage.getItem('token');

        if (token) {
            return 'Bearer ' + token;
        } else {
            return '';
        }
    }

    handleSuccess(response) {
        return response;
    }

    handleError(error){
        console.log("ERROR", error.response.status, error)
        if (!error || !error.response){
            return Promise.reject(error)
        }
        switch (error.response.status) {
            case 401:
                //let  history = useHistory()
                //history.push("/login");
                //history.pushState(null, 'login');
                break;
            case 404:
                break;
            default:
                break;
        }
        return Promise.reject(error)
    }

    doRequest(params, callback, isLogin=false, withHeader=true, file=false, nameFile = '', load=true, printFile = false){
        if(withHeader){
            this.createHeader();
        }
        //const errors = strings.error
        //const netMsg = strings.net

        if(document.getElementById("loadingpanel") && load){
             var div = document.getElementById("loadingpanel");
             var count = +div.getAttribute("data-calls");
             count++;
             div.setAttribute("data-calls",count);
             if(count >= 1) {
                document.getElementById("loadingpanel").style.display = "flex";
             }
            
        }

        return this.service.request(params).then((response) => {

            
        if(document.getElementById("loadingpanel")  && load){
             var div = document.getElementById("loadingpanel");
             var count = +div.getAttribute("data-calls");
             count--;
             div.setAttribute("data-calls",count);
             if(count <=0){
                document.getElementById("loadingpanel").style.display = "none";
             }
            
        }
            //let error  = response.error

          /*  if (isLogin){
                data   = response.data
            }*/
            let status = response.status

            if (status !== 200){
                return
            }

            if (file) {
              debugger;
              if(printFile){
                var pdfFile = new Blob([response.data], {
                    type: "application/pdf"
                });
                var pdfUrl = URL.createObjectURL(pdfFile);
                print(pdfUrl);
              } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', nameFile); //or any other extension
                document.body.appendChild(link);
                link.click();
                callback(true, null)
              }
            } else{
              let data   = response.data

              /*if (params.method === 'PUT' || params.method === 'POST'){
                    if (!isLogin) {
                      const msg = "Guardado correctamente"
                      toast.notify(msg, {position:'top', duration: 5000})
                    }
              } else if (params.method === 'DELETE'){
                  const msg = "Borrado correctamente"
                  toast.notify(msg, {position:'top', duration: 5000})
              }*/

              callback(data, null)
            }


        }).catch(err =>{
            if(document.getElementById("loadingpanel")  && load){
                var div = document.getElementById("loadingpanel");
                var count = +div.getAttribute("data-calls");
                count--;
                div.setAttribute("data-calls",count);
                if(count <=0){
                    document.getElementById("loadingpanel").style.display = "none";
                }
                
            }

            console.log(err.response?.status, err)
            if(err.response !== undefined && err.response.data !== undefined){
              let bodyError = err.response.data;
              var mes = "";
              var server = false;
              if (bodyError && bodyError.message){
                if (params.method === 'DELETE'){
                    mes = 'delete.errordelete';
                } else {
                   mes = bodyError.message;
                   server = true;
                }
              }else if (params.method === 'PUT' || params.method === 'POST'){
                    if (!isLogin){
                        mes = 'delete.errorsave';
                    }
              } else if (params.method === 'DELETE'){
                    if (!isLogin){
                        mes = 'delete.errordel';
                    }
              }
            } else if (params.method === 'PUT' || params.method === 'POST'){
                  if (!isLogin){
                    mes = 'delete.errorsave';
                  }
            } else if (params.method === 'DELETE'){
                mes = 'delete.errordel';

            }
            var response = {
                mes: mes,
                server: server
            }
            callback(null, response)
        });
    }

    async get(path, callback, isLogin=false, load=true) {
        let params = {
            method: 'GET',
            url: this.HOST+path,
            responseType: 'json'
        }

        if (!callback) {
            return new Promise((resolve, reject) => {
                this.doRequest(params, (data, error) => {
                    if (error) reject(error);
                    else resolve(data);
                }, isLogin, true, false, '', load);
            });
        }
        
        return this.doRequest(params, callback,isLogin,true,false,'',load)
    }

    getFile(path,name, callback) {
        let params = {
            method: 'GET',
            url: this.HOST+path,
            responseType: 'blob'
        }
        return this.doRequest(params, callback, false,true, true,name)
    }

    getFilePrint(path,name, callback) {
        let params = {
            method: 'GET',
            url: this.HOST+path,
            responseType: 'blob'
        }
        return this.doRequest(params, callback, false,true, true,name,true,true)
    }

    patch(path, payload, callback) {
        let params = {
            method: 'PATCH',
            url: this.HOST+path,
            responseType: 'json',
            data: payload
        }
        return this.doRequest(params, callback)
    }

    post(path, payload, callback, isLogin=false, load=true) {
        let params = {
            method: 'POST',
            url: this.HOST+path,
            responseType: 'json',
            data: payload
        }
        return this.doRequest(params, callback, isLogin,true,false,'',load)
    }

    postFile(path,payload,name, callback) {
        let params = {
            method: 'POST',
            url: this.HOST+path,
            responseType: 'blob',
            data: payload
        }
        return this.doRequest(params, callback, false,true, true,name,true)
    }
    postFilePrint(path,payload,name, callback) {
        let params = {
            method: 'POST',
            url: this.HOST+path,
            responseType: 'blob',
            data: payload
        }
        return this.doRequest(params, callback, false,true, true,name,true,true)
    }


    postMultipartWithName(url, file, filename, callback, isLogin=false){

        let formData = new FormData();
        formData.append('file', file,filename);

        let params = {
            method: 'POST',
            url: this.HOST+url,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return this.doRequest(params, callback, isLogin)
    }

    postMultipart(url, file, callback, isLogin=false){

        let formData = new FormData();
        formData.append('file', file);

        let params = {
            method: 'POST',
            url: this.HOST+url,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return this.doRequest(params, callback, isLogin)
    }

    async postMultipartAsync(url, file, callback, isLogin=false){

        let formData = new FormData();
        formData.append('file', file);

        let params = {
            method: 'POST',
            url: this.HOST+url,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return this.doRequest(params, callback, isLogin)
    }

    delete(path, callback) {
        let params = {
            method: 'DELETE',
            url: this.HOST+path,
            responseType: 'json'
        }
        return this.doRequest(params, callback)
        //return this.service.delete(path).then((response) => callback(response.status, response.data));
    }

    put(path, payload, callback) {


        let params = {
            method: 'PUT',
            url: this.HOST+path,
            responseType: 'json',
            data: payload
        }
        return this.doRequest(params, callback, false)

    }
}
export default new BaseService();
