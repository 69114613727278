import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { danhosFinishService } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'
import { nameCarDamageZone, urlImage } from '../../utils/Utils'
import { Box, width } from '@mui/system'
import { Button, Modal } from '@mui/material'

export default function TabViewFinish(props) {
    const [carDamage, setCarDamage] = useState<any>({})
    const [path, setPath] = useState<any>(null)
    const [openModal, setOpenModal] = useState<any>(false)

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: '80%',
        height: '80vh',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        overflowY: 'scroll',
        p: 4,
    }

    useEffect(() => {}, [])

    useEffect(() => {
        if (props.idShare != null) {
            search(props.idShare)
        }
    }, [props.idShare])

    useEffect(() => {
        if (path != null) {
            setOpenModal(true)
        }
    }, [path])

    const search = function (idShare) {
        var filter = { share: idShare }

        danhosFinishService.pageFilter(0, 1, filter, (data, error) => {
            if (data && data.data && data.data.length >= 1) {
                setCarDamage(data.data[0])
            }
        })
    }
    const NormalButton = (props) => <button {...props} type="button" />

    const handleCloseModal = () => {
        setPath(null)
        setOpenModal(false)
    }

    return (
        <>
            <Grid container>
                {carDamage &&
                    carDamage['images'] &&
                    carDamage['images'].length > 0 &&
                    carDamage['images'].map((row) => {
                        return (
                            <Grid xs={4} sm={2} style={{ padding: '0 10px' }}>
                                <img
                                    src={urlImage(row['path'])}
                                    alt=""
                                    style={{ width: 'calc(100% - 20px)' }}
                                />
                                <h4>
                                    {nameCarDamageZone(row['carDamageZone'])}
                                    <Button
                                        type="button"
                                        onClick={(evt) => {
                                            setPath(urlImage(row['path']))
                                        }}
                                        component={NormalButton}
                                        style={{
                                            background: '#83c63b',
                                            color: 'white',
                                            marginLeft: '20px',
                                        }}
                                    >
                                        {' '}
                                        <label>Ampliar</label>{' '}
                                    </Button>
                                </h4>
                            </Grid>
                        )
                    })}
            </Grid>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={style}>
                    {path && <img src={path} width={'100%'} />}
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleCloseModal()}
                        >
                            {' '}
                            Cerrar
                        </Button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
