import CrudTable from '../../../crud/CrudTable'
import { carDamageTypeService as service } from '../../../../services'

const initFilter = {
    name: null,
}

export default function CarDamageType() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
    ]

    const paintCell = {}

    return (
        <CrudTable
            service={service}
            urledit={'/cardamagetype/edit/'}
            urlnew={'/cardamagetype/new/'}
            filter={initFilter}
            i18n={'cardamagetype'}
            paintCell={paintCell}
            fieldstable={['id', 'name']}
            estructureForm={createEstructureFilter}
        />
    )
}
