import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { faqsCategoryService as service } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    title: null,
}

export default function FormFaqsCategory() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'title',
            col: 6,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit = { ...objectedit, ...element }
        return objectedit
    }

    const isValid = function (object) {
        if (!object['title']) {
            return t('faqsCategory.error.title')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'faqsCategory'}
                urledit={'/faqs/category/edit/'}
                urlCancel={'/faqs/category'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
