import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import {
    danhosService,
    userService,
    carDamageTypeService,
} from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'

export default function TabDanhosRent(props) {
    const [filter, setFilter] = useState({
        car: null,
        user: null,
        rent: props.idRent,
        to: null,
        rentalState: null,
        paymentState: null,
    })

    const { t } = useTranslation()

    const createEstructureFilter = [
        {
            type: 'autocomplete',
            name: 'user',
            service: userService.combo,
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'carDamageType',
            service: carDamageTypeService.combo,
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'carDamageZone',
            options: [
                { key: 'FRONT', name: 'Frente' },
                { key: 'BACK', name: 'Culo' },
                { key: 'LEFT', name: 'Lateral izquierdo' },
                { key: 'RIGHT', name: 'Lateral derecho' },
                { key: 'TOP', name: 'Techo' },
            ],
            col: 3,
        },
    ]

    const paintCell = {
        share: (value, object) => {
            if (object['share'] && object['share']['id']) {
                return (
                    <a
                        href={'/share/edit/' + object['share']['id']}
                        style={{ color: '#83c63b', textDecoration: 'none' }}
                    >
                        {' '}
                        Share {object['share']['id']}
                    </a>
                )
            } else if (object['rental'] && object['rental']['id']) {
                return (
                    <a
                        href={'/rent/edit/' + object['rental']['id']}
                        style={{ color: '#83c63b', textDecoration: 'none' }}
                    >
                        {' '}
                        Share {object['rental']['id']}
                    </a>
                )
            } else {
                return <></>
            }
        },
        carDamageZone: (value, object) => {
            if (value) {
                if (value == 'FRONT') {
                    return <>Frente</>
                } else if (value == 'BACK') {
                    return <>Culo</>
                } else if (value == 'LEFT') {
                    return <>Lateral izquierdo</>
                } else if (value == 'RIGHT') {
                    return <>Lateral derecho</>
                } else if (value == 'TOP') {
                    return <>Techo</>
                } else {
                    return <>{value}</>
                }
            }
        },
        images: (value, object) => {
            if (
                object['images'] != null &&
                object['images'].length > 0 &&
                object['images'][0]['path']
            ) {
                return (
                    <img
                        src={getUrlImage(object['images'][0]['path'])}
                        width="100"
                        height="auto"
                        style={{ maxWidth: '80%' }}
                    />
                )
            } else {
                return <></>
            }
        },
    }

    const getUrlImage = function (namefile) {
        if (namefile) {
            if (namefile.startsWith('http')) {
                return namefile
            } else {
                var nameImage = namefile
                nameImage = nameImage.startsWith('/')
                    ? nameImage
                    : '/' + nameImage
                return process.env.REACT_APP_HOST + '/image' + nameImage
            }
        } else {
            return ''
        }
    }

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            rent: props.idRent,
            user: null,
            car: null,
            to: null,
            rentalState: null,
            paymentState: null,
        })
    }, [props.idRent])

    return (
        <>
            <CrudTab
                service={danhosService}
                filter={filter}
                i18n={'danhos'}
                fieldstable={[
                    'images',
                    'user.email',
                    'carDamageZone',
                    'typesDamage.name',
                    'car.number',
                    'description',
                ]}
                defaultValues={{ serie: '', number: 'Borrador' }}
                paintCell={paintCell}
                estructureForm={createEstructureFilter}
                haveother={false}
                selectable={false}
                urledit={'/danhos/edit/'}
                deleteaction={false}
                labels={{
                    'typesDamage.name': 'Tipo de daño',
                }}
            />
            <ToastContainer />
        </>
    )
}
