import CrudTable from '../../../crud/CrudTable'
import {
    modelcarService as service,
    cartypeService,
} from '../../../../services'

const initFilter = {
    name: null,
}

export default function Modelcar() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'input',
            name: 'brand',
            col: 4,
        },
        {
            type: 'input',
            name: 'model',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'cardType',
            service: cartypeService.combo,
            col: 4,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/modelcar/edit/'}
            urlnew={'/modelcar/new/'}
            filter={initFilter}
            i18n={'modelcar'}
            fieldstable={[
                'picture',
                'name',
                'brand',
                'model',
                'cardType.name',
                'typeCar',
                'fuel_capacity',
                'seats',
                'bateryOk',
                'bateryOk',
                'bateryWarning',
                'bateryKO',
            ]}
            estructureForm={createEstructureFilter}
        />
    )
}
