import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import {
    rateUserService,
    userService,
    carDamageTypeService,
} from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'

export default function TabRateRent(props) {
    const [filter, setFilter] = useState({
        car: null,
        user: null,
        rent: props.idRent,
        to: null,
        rentalState: null,
        paymentState: null,
    })

    const { t } = useTranslation()

    const createEstructureFilter = [
        {
            type: 'autocomplete',
            name: 'user',
            service: userService.combo,
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'carDamageType',
            service: carDamageTypeService.combo,
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'carDamageZone',
            options: [
                { key: 'FRONT', name: 'Frente' },
                { key: 'BACK', name: 'Culo' },
                { key: 'LEFT', name: 'Lateral izquierdo' },
                { key: 'RIGHT', name: 'Lateral derecho' },
                { key: 'TOP', name: 'Techo' },
            ],
            col: 3,
        },
    ]

    const paintCell = {
        rate: (value, object) => {
            return (
                <>
                    {value != null && value >= 1 ? (
                        <img src={'/stargreen.png'} alt="" width={'30px'} />
                    ) : (
                        <img src={'/starwhite.png'} alt="" width={'30px'} />
                    )}
                    {value != null && value >= 2 ? (
                        <img src={'/stargreen.png'} alt="" width={'30px'} />
                    ) : (
                        <img src={'/starwhite.png'} alt="" width={'30px'} />
                    )}{' '}
                    {value != null && value >= 3 ? (
                        <img src={'/stargreen.png'} alt="" width={'30px'} />
                    ) : (
                        <img src={'/starwhite.png'} alt="" width={'30px'} />
                    )}{' '}
                    {value != null && value >= 4 ? (
                        <img src={'/stargreen.png'} alt="" width={'30px'} />
                    ) : (
                        <img src={'/starwhite.png'} alt="" width={'30px'} />
                    )}{' '}
                    {value != null && value >= 5 ? (
                        <img src={'/stargreen.png'} alt="" width={'30px'} />
                    ) : (
                        <img src={'/starwhite.png'} alt="" width={'30px'} />
                    )}
                </>
            )
        },
    }

    const getUrlImage = function (namefile) {
        if (namefile) {
            if (namefile.startsWith('http')) {
                return namefile
            } else {
                var nameImage = namefile
                nameImage = nameImage.startsWith('/')
                    ? nameImage
                    : '/' + nameImage
                return process.env.REACT_APP_HOST + '/image' + nameImage
            }
        } else {
            return ''
        }
    }

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            rent: props.idRent,
            user: null,
            car: null,
            to: null,
            rentalState: null,
            paymentState: null,
        })
    }, [props.idRent])

    return (
        <>
            <CrudTab
                service={rateUserService}
                filter={filter}
                hiddenFilter={true}
                i18n={'rateuser'}
                fieldstable={['car.number', 'rate', 'comment']}
                paintCell={paintCell}
                estructureForm={createEstructureFilter}
                haveother={false}
                selectable={false}
                editable={false}
                deleteaction={false}
            />
            <ToastContainer />
        </>
    )
}
