import React, { useState, useEffect } from 'react'
import CrudTab from '../../crud/CrudTab'
import { shareLogService } from '../../../services'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'

const moment = require('moment')

export default function TabShareLog(props) {
    const [filter, setFilter] = useState({
        share: props.idShare,
        title: null,
        description: null,
        from: null,
        to: null,
    })

    const { t } = useTranslation()

    const createEstructureFilter = [
        {
            type: 'input',
            name: 'title',
            col: 4,
        },
        {
            type: 'input',
            name: 'from',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'input',
            name: 'to',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'textarea',
            name: 'description',
            col: 4,
        },
    ]

    const paintCell = {
        createDate: (value, object) => {
            if (value) {
                return (
                    <>{moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')}</>
                )
            }
        },
    }

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            share: props.idShare,
            title: null,
            description: null,
            from: null,
            to: null,
        })
    }, [props.idShare])

    console.log('Filtro aplicado:', filter)
    return (
        <>
            <CrudTab
                service={shareLogService}
                filter={filter}
                i18n={'shareLog'}
                newbtn={true}
                urlnew={'/share-log/share/' + props.idShare}
                fieldstable={[
                    'createDate',
                    'title',
                    'description',
                    'createUser',
                ]}
                paintCell={paintCell}
                estructureForm={createEstructureFilter}
                haveother={false}
                selectable={false}
                editable={false}
                deleteaction={false}
            />
            <ToastContainer />
        </>
    )
}
