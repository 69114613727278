import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { rentalLogService as service, rentService } from '../../../services'
import { useTranslation } from 'react-i18next'

const moment = require('moment')

const initElement = {
    rental: { id: null },
    title: '',
    description: '',
}

export default function FormRentalLog() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
        rent: string
    }
    let { rent } = useParams<FormParms>()
    const [idRent, setIdRent] = useState(rent ? Number(rent) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'rental.id',
            col: 4,
            service: rentService.combo,
            readonly: idRent > 0,
        },
        {
            type: 'input',
            name: 'title',
            col: 4,
        },
        {
            type: 'textarea',
            name: 'description',
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['rental'] = element.rental ? element.rental : { id: null }
        objectedit['title'] = element.title ? element.title : ''
        objectedit['description'] = element.description
            ? element.description
            : ''

        if (element['user'] == null || element['user']['id'] == null) {
            objectedit['user'] = {
                id: -1,
                name: 'Todos',
                email: 'todos@todos.com',
            }
        }

        if (objectedit['rental']['id']) {
            objectedit['rental']['name'] =
                objectedit['rental']['id'] +
                ' -- ' +
                moment(Date.parse(objectedit['rental']['start'])).format(
                    'DD/MM/YYYY'
                ) +
                ' -- ' +
                (objectedit['rental']['user'] &&
                objectedit['rental']['user']['email']
                    ? objectedit['rental']['user']['email']
                    : '')
        }
        return objectedit
    }

    const isValid = function (object) {
        if (!object['rental']) {
            return t('rentalLog.error.rental')
        }
        if (!object['title']) {
            return t('rentalLog.error.title')
        }
        if (!object['description']) {
            return t('rentalLog.error.description')
        }
        return null
    }

    return (
        <>
            <CrudForm
                objectform={{
                    ...initElement,
                    ...{ rental: { id: idRent, name: '' } },
                }}
                estructureform={estructureform}
                i18n={'rentalLog'}
                urledit={'/rental-log/edit/'}
                urlCancel={idRent > 0 ? '/rent/edit/' + idRent : '/rental-log'}
                returnToSave={idRent > 0}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
