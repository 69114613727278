import CrudTable from '../../crud/CrudTable'
import { faqsService as service, faqsCategoryService } from '../../../services'

const initFilter = {
    title: null,
    category: null,
}

export default function Faqs() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'title',
            col: 4,
        },
        {
            type: 'input',
            name: 'description',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'category',
            service: faqsCategoryService.combo,
            col: 4,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/faqs/edit/'}
            urlnew={'/faqs/new/'}
            filter={initFilter}
            i18n={'faqs'}
            fieldstable={['id', 'title', 'category.title']}
            estructureForm={createEstructureFilter}
        />
    )
}
