import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { userhistoryService } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import Grid from '@mui/material/Grid'
import Moment from 'react-moment'

export default function TabUserHistory(props) {
    const [filter, setFilter] = useState({
        user: props.idclient,
        from: null,
        to: null
    })

    const { t } = useTranslation()

    const createEstructureFilter = [
        {
            type: 'input',
            name: 'from',
            inputtype: 'datetime-local',
            col: 6,
            label: t('userHistory.filter.field.from')
        },
        {
            type: 'input',
            name: 'to',
            inputtype: 'datetime-local',
            col: 6,
            label: t('userHistory.filter.field.to')
        }
    ]

    const paintCell = {
        createDate: (value) => new Date(value).toLocaleDateString(),
        validateState: (value, object) => {
            if (value == 'VALIDATE') {
                return <span style={{ color: 'green' }}>Validada</span>
            } else if (value == 'NONE') {
                return (
                    <span style={{ color: 'black' }}>
                        Documentación no enviada
                    </span>
                )
            } else if (value == 'REJECT') {
                return <span style={{ color: 'red' }}>Rechazada</span>
            } else if (value == 'PENDING') {
                return (
                    <span style={{ color: 'orange' }}>
                        Pendiente de validar
                    </span>
                )
            } else {
                return (
                    <span style={{ color: 'black' }}>
                        Documentación no enviada
                    </span>
                )
            }
        }
    }

    useEffect(() => { }, [])

    useEffect(() => {
        setFilter(prev => ({ ...prev, user: props.idclient }))
    }, [props.idclient])

    return (
        <>
            <CrudTab
                service={userhistoryService}
                filter={filter}
                i18n={'userHistory'}
                // fieldstable={[
                //     'createDate',
                //     'name|lastname',
                //     'email',
                //     'phone',
                //     'department|street|number|floor|door|city|state|cp|country',
                //     'nif',
                //     'dni_day|dni_month|dni_year',
                //     'birth_day|birth_month|birth_year',
                //     'license_front|license_back',
                //     'license_day|license_month|license_year',
                //     'licenseInit_day|licenseInit_month|licenseInit_year',
                //     'validateState',
                //     'motive',
                //     'idTecalis'
                // ]}
                fieldstable={[
                    'createDate',
                    'name',
                    'lastname',
                    'email',
                    'phone',
                    'department',
                    'street',
                    'number',
                    'floor',
                    'door',
                    'city',
                    'state',
                    'cp',
                    'country',
                    'nif',
                    'dni_day',
                    'dni_month',
                    'dni_year',
                    'birth_day',
                    'birth_month',
                    'birth_year',
                    'license_front',
                    'license_back',
                    'license_day',
                    'license_month',
                    'license_year',
                    'licenseInit_day',
                    'licenseInit_month',
                    'licenseInit_year',
                    'validateState',
                    'motive',
                    'idTecalis'
                ]}
                paintCell={paintCell}
                estructureForm={createEstructureFilter}
                haveother={false}
                selectable={false}
                editable={false}
                deleteaction={false}
                onFilterUpdate={(newFilter) => setFilter(newFilter)}
            />
            <ToastContainer />
        </>
    )
}
