import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import {
    danhosService as service,
    carDamageTypeService,
} from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    car: { id: null, name: '' },
    share: { id: null, name: '' },
    rental: { id: null, name: '' },
    user: { id: null, name: '' },
    carDamageZone: null,
    description: null,
    typesDamage: [],
    images: [],
    lat: null,
    lng: null,
}

export default function FormDanhos() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'title',
            name: 'titleuser',
            col: 12,
        },
        {
            type: 'input',
            name: 'user.name',
            col: 4,
        },
        {
            type: 'input',
            name: 'user.lastname',
            col: 4,
        },
        {
            type: 'input',
            name: 'user.email',
            col: 4,
        },
        {
            type: 'title',
            name: 'titleveh',
            col: 12,
        },
        {
            type: 'input',
            name: 'car.brand',
            col: 4,
        },
        {
            type: 'input',
            name: 'car.model',
            col: 4,
        },
        {
            type: 'input',
            name: 'car.number',
            col: 4,
        },
        {
            type: 'title',
            name: 'titleshare',
            col: 12,
        },
        {
            type: 'input',
            name: 'share.id',
            col: 6,
        },
        {
            type: 'input',
            name: 'rental.id',
            col: 6,
        },

        {
            type: 'title',
            name: 'titledanhos',
            col: 12,
        },
        {
            type: 'autocomplete',
            name: 'carDamageZone',
            options: [
                { key: 'FRONT', name: 'Frente' },
                { key: 'BACK', name: 'Culo' },
                { key: 'LEFT', name: 'Lateral izquierdo' },
                { key: 'RIGHT', name: 'Lateral derecho' },
                { key: 'TOP', name: 'Techo' },
            ],
            col: 6,
        },
        {
            type: 'autocomplete',
            name: 'typesDamage',
            multiple: true,
            service: carDamageTypeService.combo,
            col: 6,
        },
        {
            type: 'textarea',
            name: 'description',
            col: 12,
        },
        {
            type: 'gallery',
            name: 'images',
            imagekey: 'path',
            altkey: 'alt',
            idkey: 'id',
            col: 12,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit = { ...objectedit, ...element }
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('ads.error.name')
        }
        if (!object['picture']) {
            return t('ads.error.picture')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'danhos'}
                urledit={'/danhos/edit/'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                onlyRead={true}
            />
        </>
    )
}
