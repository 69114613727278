import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Snackbar, Tooltip } from '@mui/material';
import { ContentCopy, Done } from '@mui/icons-material';
import { carService, deviceService } from '../../../services';

const staticData = [
    { accion: 'ABRIR PUERTAS', comando: 'lvcanopenalldoors' },
    { accion: 'CERRAR PUERTAS', comando: 'lvcanclosealldoors' },
    { accion: 'BLOQUEAR POR CANBUS', comando: 'lvcanblockengine' },
    { accion: 'DESBLOQUEAR POR CANBUS', comando: 'lvcanunblockengine' },
    { accion: 'BLOQUEAR POR DOUT', comando: 'setdigout 000' },
    { accion: 'DESBLOQUEAR POR DOUT', comando: 'setdigout 111' },
    { accion: 'ABRIR DEPOSITO PUERTAS', comando: 'lvcanopentrunk' },
    { accion: 'ENCENDER LUCES DE EMERGENCIA', comando: 'lvcanturninglights' },
    { accion: 'RECOGER INFORMACION', comando: 'getstatus' },
    { accion: 'RECOGER POSICION', comando: 'getgps' },
];

interface TabCommandsSMSProps {
    idcar: number;
}

export default function TabCommandsSMS(props: TabCommandsSMSProps) {
    const [phone, setPhone] = useState<string>('');
    const [smsPin, setSmsPin] = useState<string>('');
    const { idcar } = props;

    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [copiedIndex, setCopiedIndex] = useState<number>(-1);

    const handleCopy = (text: string, index: number) => {
        navigator.clipboard.writeText(text);
        setOpenSnackbar(true);
        setCopiedIndex(index);
        setTimeout(() => setCopiedIndex(-1), 2000);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        const fetchDeviceData = async () => {
            try {
                if (idcar) {
                    const response = await deviceService.getDeviceByCarId(idcar);
                    setPhone(response.phone || 'N/A');
                    setSmsPin(response.sms_pin || 'N/A');
                }
            } catch (error) {
                console.error('Error obteniendo dispositivo:', error);
                setPhone('N/A');
                setSmsPin('N/A');
            }
        };
    
        fetchDeviceData();
    }, [idcar]);

    return (
        <TableContainer component={Paper}>
            <Typography variant="h5" style={{ padding: '20px' }}>
                Teléfono: {phone} - Pin: {smsPin}
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>ACCIÓN</strong></TableCell>
                        <TableCell><strong>MENSAJE COMANDO PARA EJECUTAR ACCIÓN</strong></TableCell>
                        <TableCell><strong>COPIAR</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {staticData.map((row, index) => {
                        const commandText = `${smsPin} ${smsPin} ${row.comando}`;
                        return (
                            <TableRow key={index}>
                                <TableCell>{row.accion}</TableCell>
                                <TableCell>{commandText}</TableCell>
                                <TableCell>
                                    <Tooltip title="Copiar al portapapeles">
                                        <IconButton 
                                            onClick={() => handleCopy(commandText, index)}
                                            color={copiedIndex === index ? 'success' : 'default'}
                                        >
                                            {copiedIndex === index ? <Done fontSize="small" /> : <ContentCopy fontSize="small" />}
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                message="Texto copiado al portapapeles"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            />
        </TableContainer>
    );
}